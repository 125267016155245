import './App.css';
import Login from './mainPage/Login'
import Header from './other/Header';
import { BrowserRouter as Router ,Route , Switch  } from 'react-router-dom/cjs/react-router-dom.min';
import Homepage from './other/Homepage';

function App() {
  return (
    <>
    <Router>
      <Header/>
      <Switch>
        

      <Route exact path='/'>  <Homepage/>  </Route>
      <Route exact path='/login'>    <Login/></Route>
  
      </Switch>
      </Router>
    </>
  );
}

export default App;
