import React from 'react'
import './main.css'
import imgLogo from '../image/logo.PNG'
import {Link} from 'react-router-dom'

const Header = () => {
  return (
    <>
        <div className="header">
            <div className="logo_nav">
                <img src={imgLogo} alt="" />
            </div>
            <div className="right_nav">
                <Link to='/login' className='header_btn' >Login</Link>
            </div>
        </div>
    </>
  )
}

export default Header