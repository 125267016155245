import React from 'react'
import img_log from '../image/logo.PNG'

const Homepage = () => {
  return (
   <div className="home_page">
    <div className="bg_main"></div>
    <div className="content">
        <h1>Instagroup</h1>
        <p>We provide the instagram different groups, you can access through us by links, which is having diffrent products promotions and many other types of the things you can have. So, what are you waiting for, just create/login in your Instagroup account and make your products popular.</p>
        <p>Don't Login with your Instagram User Password, use only I'd , not even email and password.</p>
    </div>
   </div>
  )
}

export default Homepage